<template lang="pug">
  .page(v-if="hasInvitation")
    intro-widget
    info-widget(
      :invitation="invitation")
    .widget(
      key="photo"
      style="z-index: 13")
      v-animate
        img(src="@/assets/img/second.jpg")
    address-widget
    RSVP-widget(
      :invitation="invitation")
    slider-widget
    timing-widget
    present-widget
    countdown-widget
    telegram-widget
  no-invitation-id(v-else)
</template>

<script>
import { initializeApp } from 'firebase/app'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'

import IntroWidget from '@/components/widgets/IntroWidget'
import InfoWidget from '@/components/widgets/InfoWidget'
import AddressWidget from '@/components/widgets/AddressWidget'
import PresentWidget from '@/components/widgets/PresentWidget'
import RSVPWidget from '@/components/widgets/RSVPWidget'
import SliderWidget from '@/components/widgets/SliderWidget'
import TimingWidget from '@/components/widgets/TimingWidget'
import CountdownWidget from '@/components/widgets/CountdownWidget'
import TelegramWidget from '@/components/widgets/TelegramWidget'
import NoInvitationId from '@/components/widgets/NoInvitationId'

const firebaseConfig = {
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
}

initializeApp(firebaseConfig)

const db = getFirestore()
const INVITATIONS = 'invitations'

export default {
  name: 'Index',

  components: {
    IntroWidget,
    InfoWidget,
    PresentWidget,
    AddressWidget,
    RSVPWidget,
    SliderWidget,
    TimingWidget,
    CountdownWidget,
    TelegramWidget,
    NoInvitationId
  },

  mounted () {
    this.initialize()
  },

  data: () => ({
    invitation: null
  }),

  computed: {
    hasInvitation () {
      return !!this.$store.getters.invitationId
    }
  },

  methods: {
    initialize () {
      const id = this.$store.getters.invitationId
      onSnapshot(doc(db, INVITATIONS, id), (doc) => {
        this.invitation = doc.data()
        if (!this.invitation) {
          this.$store.dispatch('setInvitationId', null)
        } else {
          this.$i18n.locale = this.invitation.lang
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>
