<template lang="pug">
  .w-not-invitation-id.widget.widget--screen-size
    img.w-not-invitation-id__logo(src="@/assets/svg/invito.svg")
    .w-not-invitation-id__content
      img.w-not-invitation-id__heart(src="@/assets/svg/link.svg")
      p(v-html="$t('no_invitation.text_1')")
    .w-ps
      span made with
      img.w-ps__icon(src="@/assets/svg/heart-light.svg")
      span by
      a.link(
        target="_blank"
        href="https://instagram.com/invito.link/") invito.link
</template>

<script>
export default {
  name: 'NoInvitationId'
}
</script>

<style lang="scss">
  @import '@/sass/abstracts/_variables.scss';

  .w-not-invitation-id {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9F9184;

    &__logo {
      position: absolute;
      top: 3rem;
      width: 7rem;
      margin: 0 auto;
    }

    &__heart {
      width: 4rem;
      margin-bottom: 2rem;
    }

    &__content {
      color: #F8EBDD;
      font-size: 2.2rem;
      font-weight: 300;
      text-align: center;
      line-height: 2.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        text-transform: none;
      }
    }

    .w-ps,
    .link {
      color: #F8EBDD;
    }
  }
</style>
