<template lang="pug">
  .w-info.widget
    v-animate
      img.w-info__decor-top(
        src="@/assets/illustrations/flower.svg")
    .w-info__content
      .title {{ title }}
      .w-info__subtitle.script-title {{ guests }}
      p(v-html="$t('info.text_1', { you })")
      p.script-title.mb-3 {{ $t('info.text_2') }}
      p.mb-3(v-html="$t('info.text_4')")
      img.w-info__decor(src="@/assets/illustrations/flower-branch.svg")
      p.mb-3(v-html="$t('info.text_5')")
      img.w-info__decor(src="@/assets/illustrations/flower-branch.svg")
      p {{ $t('info.text_7') }}
    v-animate
      img.w-info__decor-bottom(
        src="@/assets/illustrations/flower.svg")
</template>

<script>
export default {
  name: 'InfoWidget',

  props: {
    invitation: Object
  },

  computed: {
    title () {
      if (this.invitation && this.invitation.texts.caption) {
        return this.$t(`base.${this.invitation.texts.caption}`)
      }
      return this.$t('base.dear')
    },

    guests () {
      if (this.invitation) {
        return this.invitation.guests
          .map(item => {
            if (item.name) {
              const index = item.name.indexOf('(')
              if (index !== -1) {
                return item.name.substring(0, index - 1)
              }
            }
            return item.name
          })
          .join(', ')
          .replace(/,(?=[^,]*$)/, ` ${this.$t('base.and')}`)
      }
      return '-'
    },

    you () {
      if (this.invitation && this.invitation.texts.you) {
        return this.$t(`base.${this.invitation.texts.you}`)
      }
      return this.$t('base.you')
    }
  }
}
</script>

<style lang="scss" scoped>
  .w-info {
    padding: 10rem 0;

    &__content {
      position: relative;
      padding: 0 2rem;
      text-align: center;
      z-index: 1;
    }

    &__decor {
      display: inline-block;
      width: 7.4rem;
      height: auto;
      margin-bottom: 3rem;
    }

    &__decor-top {
      position: absolute;
      height: 48rem;
      width: 115%;
      top: -27rem;
      left: -2rem;
      right: -2rem;
      transform: rotate(22deg);
    }

    &__decor-bottom {
      position: absolute;
      height: 48rem;
      width: 115%;
      bottom: -27rem;
      left: -2rem;
      right: -2rem;
      transform: rotate(-153deg);
    }

    &__subtitle {
      margin-top: 1.4rem;
      margin-bottom: 2.8rem;
      line-height: 1.8;
    }
  }
</style>
