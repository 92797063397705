<template lang="pug">
  .w-telegram.widget(id="telegram")
    v-animate
      img.w-telegram__decor-right(src="@/assets/illustrations/flower.svg")
      img.w-telegram__decor-left(src="@/assets/illustrations/flower.svg")
    .w-telegram__content
      img.w-telegram__profile(src="@/assets/img/profile-03.png")
      p.w-telegram__text(v-html="$t('messanger.text_1')")
      a.btn(
        target="_blank"
        href="https://t.me/+5xppWRCN0jE3NTA6")
        .icon
          telegram-icon
        span {{ $t('messanger.text_2') }}
    .w-ps
      span made with
      .w-ps__icon
        heart-icon
      span by
      a.link(
        target="_blank"
        href="https://instagram.com/invito.link/") invito.link
</template>

<script>
export default {
  name: 'TelegramWidget'
}
</script>

<style lang="scss" scoped>
  @import '../../sass/abstracts/_variables.scss';

  .w-telegram {
    overflow: hidden;
    background-color: $color-messenger-widget-bg;

    .btn {
      color: $color-primary;
      text-transform: uppercase;
      background-color: #F8EBDD;
    }

    .w-ps {
      background-color: $color-messenger-widget-bg;
    }

    &__content {
      position: relative;
      padding: 18rem 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }

    &__profile {
      width: 15rem;
      margin-bottom: 5rem;
    }

    &__text {
      margin-bottom: 5rem;
    }

    &__decor-right {
      position: absolute;
      top: -24rem;
      right: -18rem;
      height: 48.6rem;
      width: auto;
      transform: scaleX(1) rotate(200deg);
      opacity: .7;
    }

    &__decor-left {
      position: absolute;
      height: 46.5rem;
      width: auto;
      bottom: -25rem;
      left: -5rem;
      transform: rotate(0deg);
      opacity: .7;
    }
  }
</style>
