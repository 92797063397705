<template lang="pug">
  .widget.timing-widget
    v-animate
      img.timing-widget__decor(
        src="@/assets/illustrations/flower-branch.svg")
    .timing-widget__content
      .title {{ $t('timing.caption') }}
      .timing-widget__item(
        v-for="(item, index) in items"
        :key="index")
        .timing-widget__item-time {{ item.time }}
        .timing-widget__item-label(v-html="$t(item.label)")
    v-animate
      img.timing-widget__decor(
        src="@/assets/illustrations/flower-branch.svg")
</template>

<script>
export default {
  name: 'TimingWidget',

  data: () => ({
    items: [
      { time: '13:00', label: 'timing.text_1' },
      { time: '14:30', label: 'timing.text_2' },
      { time: '16:00', label: 'timing.text_3' },
      { time: '17:00', label: 'timing.text_4' },
      { time: '18:00', label: 'timing.text_5' }
    ]
  })
}
</script>

<style lang="scss" scoped>
  .timing-widget {
    padding: 10rem 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      text-align: center;
      margin-bottom: 5rem;
    }

    &__decor {
      width: 8.5rem;
    }

    &__content {
      padding: 4rem 0;
    }

    &__item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 2.8rem;
      }

      &-time {
        width: 6rem;
        font-size: 2.3rem;
        font-family: 'Aire Bold Pro';
        margin-right: 1.5rem;
      }

      &-label {
        font-size: 1.6rem;
        line-height: 1.4;
        font-family: 'Forum';
        text-transform: uppercase;
      }
    }
  }
</style>
